import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import { Template } from "@narative/gatsby-theme-novela/src/types";
import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";

import Body from "../components/Body";

const Header = styled.h2`
  font-size: 2em;
  margin-bottom: 50px;
`;

const CVHeader = styled.h3`
  font-size: 1.33em;
`;

const Url = styled.span`
  margin-left: 10px;
`;

const Years = styled.div`
  font-size: 0.9em;
  opacity: 0.5;
`;

const MainDiv = styled(Body)`
  ${mediaqueries.desktop_medium_up`
    font-size: 1.33em;
  `}

  ${mediaqueries.tablet`
    font-size: 1em;
  `}

  ${mediaqueries.phablet`
    font-size: 1em;
  `}
`;

const CVPart = ({ company, years, url, text, skills }) => {
  return (
    <div style={{ marginBottom: "70px" }}>
      <CVHeader>{company}</CVHeader>
      <Years>
        {years}
        <Url>
          <a href={url}>{url}</a>
        </Url>
      </Years>

      <br />
      {text}
      <br />
      <div>
        <b>Acquired skills:</b> {skills}
      </div>
    </div>
  );
};

const CVPage: Template = ({ location, pageContext }) => {
  return (
    <Layout>
      <SEO pathname={location.pathname} />
      <Section>
        <MainDiv>
          <Header>CV</Header>

          <div style={{ marginBottom: "50px" }}>
            I am a highly productive software engineer who prefers to work with
            startups. I mostly do frontend work for companies, but I know
            backend in and out, and some devops, because I constantly use both
            in my own <Link to="/projects">projects</Link>. If you're interested
            in hiring me, feel free to <Link to="/contact">contact</Link> me.
          </div>

          <CVPart
            company="Monitoreal"
            url="https://monitoreal.com"
            years="2018-2021"
            text={
              <div>
                I wrote and maintained most of the device-controlling frontend
                UI for this home security startup.
              </div>
            }
            skills="React"
          ></CVPart>

          <CVPart
            company="Cargofone"
            url="https://cargofone.com/en/"
            years="2016-2018"
            text={
              <div>
                I wrote new and maintained legacy AngularJS code for this cargo
                delivery startup. At one point I also R&D'd and wrote a new
                React Native app for them.
              </div>
            }
            skills="AngularJS, React Native"
          ></CVPart>

          <CVPart
            company="Nebo"
            url="https://nebo.pro"
            years="2012-2016"
            text={
              <div>
                I was one of four first employees of this SaaS accounting
                startup. We wrote the core framework from the ground up. I
                personally wrote the middleware between frontend on Backbone and
                backend on Django. I also set up basic CI/CD for them with
                Jenkins.
              </div>
            }
            skills="BackboneJS, Jenkins, CI/CD"
          ></CVPart>

          <CVPart
            company="Bars Group"
            url="https://bars.group"
            years="2010-2012"
            text={
              <div>
                I was a part of the core framework development team. This
                framework was used by every applied Python development team in
                the company. Framework was based on the Django and ExtJS
                (popular JS UI control framework at the time). UI part of the
                framework generated custom UI controls from the Python code. I
                wrote both the Python and Javascript parts of UI controls code
                as well as some core Python code.
              </div>
            }
            skills="Python, Django"
          ></CVPart>

          <CVPart
            company="Kazan State University"
            url="https://kpfu.ru/eng"
            years="2007-2010"
            text={
              <div>
                I worked on internal university web services, like help desk and
                student grade systems. All work was done in PL/SQL language in
                Oracle databases. There was no distinction between frontend and
                backend development back then, so I did both.
              </div>
            }
            skills="Relational databases, SQL, HTML, CSS, Javascript, jQuery"
          ></CVPart>
        </MainDiv>
      </Section>
    </Layout>
  );
};

export default CVPage;
