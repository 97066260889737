import React from 'react'
import styled from '@emotion/styled'
import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";


const Body = styled.article`
  position: relative;
  padding: 5rem 0 0;
  transition: background 0.2s linear;
  color: ${p => p.theme.colors.primary};
  z-index: 100;
  max-width: 900px;

  a {
    color: ${p => p.theme.colors.primary};
    text-decoration: underline;
  }

  ${mediaqueries.desktop`
    // padding-left: 53px;
  `}

  ${mediaqueries.tablet`
    padding: 70px 0 0px;
  `}

  ${mediaqueries.phablet`
    // padding: 60px 0;
  `}
`;

export default Body;
